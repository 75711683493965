<template>
  <v-col cols="6">
    <v-row>
      <v-col>
        <v-simple-table
          dense
          style="background-color: #F5F5F5"
        >
          <template #default>
            <tbody>
              <tr>
                <th>{{ $t('parts.supplierCard.supplier') }}</th>
                <th>{{ item.title }}</th>
              </tr>
              <tr>
                <th>{{ $t('parts.supplierCard.ean') }}</th>
                <td>{{ item.ean }}</td>
              </tr>
              <tr>
                <th>{{ $t('parts.supplierCard.listPrice') }}</th>
                <td>{{ $t('currency.dkk') }} {{ item.listPrice | formatNumberFixed2 }} /{{ unit }}</td>
              </tr>
              <tr>
                <th>{{ $t('parts.supplierCard.price') }}</th>
                <td>{{ $t('currency.dkk') }} {{ item.price | formatNumberFixed2 }} /{{ unit }}</td>
              </tr>
              <tr>
                <th>{{ $t('parts.supplierCard.priceConfirmedOn') }}</th>
                <td>{{ item.priceConfirmedOn | formatDateTime }}</td>
              </tr>
              
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
    name: 'PartSupplierDetailCard',
    props: {
        item: {
            type: Object,
            required: true
        },
        unit: {
            type: String,
            required: true
        }
    }
};
</script>
