<template>
  <v-col cols="6">
    <v-row>
      <v-col>
        <v-simple-table
          dense
          style="background-color: #F5F5F5"
        >
          <template #default>
            <tbody>
              <tr>
                <th>{{ $t('parts.customerCard.customer') }}</th>
                <th>{{ getCustomer(item.customerId).text }}</th>
              </tr>
              <tr>
                <th>{{ $t('parts.customerCard.partNumber') }}</th>
                <td>{{ item.partNumber }}</td>
              </tr>
              <tr>
                <th>{{ $t('parts.customerCard.category') }}</th>
                <td>{{ getCustomerCategory(item.categoryId).text }}</td>
              </tr>
              <tr>
                <th>{{ $t('parts.customerCard.price') }}</th>
                <td>{{ $t('currency.dkk') }} {{ item.price | formatNumberFixed2 }} /{{ unit }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
    name: 'PartCustomerDetailCard',
    props: {
        item: {
            type: Object,
            required: true
        },
        unit: {
            type: String,
            required: true
        },
        customers: {
            type: Array,
            required: true
        },
        categories: {
            type: Array,
            required: true
        }
    },
    methods: {
        getCustomer(id) {
            return this.customers.find(x => x.value === id);
        },
        getCustomerCategory(id) {
            return this.categories.find(x => x.value === id);
        }
    }
};
</script>
