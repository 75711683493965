<template>
  <v-container fluid>
    <Loading :loading="!loaded" />

    <div v-if="model">
      <v-row>
        <v-col>
          <v-card>
            <v-toolbar flat color="grey lighten-4">
              <v-toolbar-title class="d-flex align-center">
                <v-btn class="mr-3" color="grey" elevation="0" fab small dark>
                  <v-icon :title="model.data.groupTitle">
                    {{ model.data.groupIcon }}
                  </v-icon>
                </v-btn>

                <span> {{ model.data.description }} </span>
              </v-toolbar-title>

              <v-spacer />

              <v-btn color="primary" to="edit" append>
                Rediger vare

                <v-icon right>
                  mdi-pencil
                </v-icon>
              </v-btn>
            </v-toolbar>

            <v-card-text>
              <v-row>
                <v-col cols="3" class="text-caption">
                  <div>
                    {{ $t('admin.part.card.partNumber') }}: {{ model.data.partNumber }}
                  </div>
                  <div>{{ $t("admin.part.card.ean") }}: {{ model.data.ean }}</div>
                  <div>{{ $t("admin.part.card.manufacturer") }}: {{ model.data.manufacturerTitle }}</div>
                  <div>
                    {{ $t("admin.part.card.manufacturerPartNumber") }}:
                    {{ model.data.manufacturerPartNumber }}
                  </div>
                </v-col>
                <v-col>
                  <span class="text-subtitle-1 admin-part-list__part-description">
                    {{ model.data.description }}
                  </span>
                </v-col>
                <v-col align-self="end" cols="2" class="text-right text-h5">
                  <span>{{ $t("currency.dkk") }} {{ model.data.listPrice | formatNumberFixed2 }}</span>
                  <span class="text-caption"> /{{ model.data.unitTitle }}</span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-card elevation="2">
            <v-card-text>
              <v-row>
                <v-col>
                  <h2>{{ $t("parts.details.suppliers") }}</h2>
                </v-col>
              </v-row>
              <v-row>
                <PartSupplierDetailCard
                  v-for="item in model.data.suppliers"
                  :key="item.supplierId"
                  :item="item"
                  :unit="model.data.unitTitle"
                />
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-card elevation="2">
            <v-card-text>
              <v-row>
                <v-col>
                  <h2>{{ $t("parts.details.customers") }}</h2>
                </v-col>
              </v-row>
              <v-row>
                <PartCustomerDetailCard
                  v-for="item in model.data.customers"
                  :key="item.customerId"
                  :item="item"
                  :unit="model.data.unitTitle"
                  :customers="model.metadata.customers"
                  :categories="model.metadata.customerCategories"
                />
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Loading from "@/components/Loading";
import PartUpsertDialog from "@/components/part/PartUpsertDialog";
import PartCustomerDetailCard from "@/components/part/PartCustomerDetailCard";
import PartSupplierDetailCard from "@/components/part/PartSupplierDetailCard";

export default {
  name: "PartDetail",
  components: {
    Loading,
    PartUpsertDialog,
    PartCustomerDetailCard,
    PartSupplierDetailCard,
  },
  data() {
    return {
      model: null,
      loaded: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      try {
        const response = await this.$AuthService.api.get(`adminpart/${this.$route.params.id}`);
        this.model = response.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loaded = true;
      }
    },
  },
};
</script>